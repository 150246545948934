import { AxiosError, AxiosResponse } from 'axios'
import HTTP_STATUS_CODES from 'http-status-codes'
import _ from 'lodash'

import {
  getParentQuestionnairesAPIPath,
  getProjectAppsAPIPath,
  getQuestionnaireAPIPath,
  getQuestionnaireByIdAPIPath,
  getQuestionnairesAPIPath,
  getSelectedQuestionnaireAPIPath,
} from 'config/apiPaths'
import { ERR_REQ_FIELD_APP } from 'config/constants'
import {
  QUESTIONNAIRE_STATUS,
  QUESTIONNAIRE_STATUS_VALUES,
  STEP_ACTIONS,
  TAG_VARIANTS,
  TOAST_MESSAGE_TYPES,
} from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import IOption from 'interfaces/common/IOption'
import IParentQuestionnaire from 'interfaces/common/IParentQuestionnaire'
import IResponseApp from 'interfaces/field/response/IResponseApp'
import IProject from 'interfaces/project/IProject'
import IProjectApp from 'interfaces/project/IProjectApp'
import IProjectQuestionnaire from 'interfaces/projectQuestionnaire/IProjectQuestionnaire'
import IQuestionnaire from 'interfaces/questionnaire/IQuestionnaire'
import IParamHandleStep from 'interfaces/questionnaire/params/IParamHandleStep'
import AxiosService from 'lib/AxiosService'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import { setErrorAlert, showAlert } from 'store/reducers/alertSlice'
import { setApp, setError, setLoading, setState } from 'store/reducers/questionnaireSlice'
import { setReviewers } from 'store/reducers/reviewSlice'
import FormHelper from 'utils/form/FormHelper'
import FormValidation from 'utils/form/FormValidation'
import SubmitHelper from 'utils/form/SubmitHelper'
import FieldCommentHelper from 'utils/formField/FieldCommentHelper'
import ProjectHelper from 'utils/project/ProjectHelper'
import ReviewHelper from 'utils/review/ReviewHelper'
import SharedHelper from 'utils/SharedHelper'

export default class QuestionnaireHelper {
  /**
   * Enable/Disable comment mode
   * @param {IQuestionnaireState} state
   * @param {Function} dispatch
   * @returns {void}
   */
  public static readonly handleCommentMode = (state: IQuestionnaireState, dispatch: Function): void => {
    dispatch(setState({ ...state, enableCommentMode: !state.enableCommentMode }))
  }
  /**
   * Get Questionnaires
   * @param {string} accessToken
   * @param {IAppContextState} appContext
   * @returns {Promise<IOption[]>}
   */
  public static readonly getQuestionnaires = async (
    accessToken: string,
    appContext: IAppContextState,
  ): Promise<IOption[]> => {
    const { tenantId } = appContext
    const axiosService = new AxiosService(accessToken)
    const questionnaires: AxiosResponse<IQuestionnaire[]> = await axiosService.get(
      getQuestionnairesAPIPath(tenantId),
      tenantId,
    )
    return _.sortBy(
      SharedHelper.getOptions(questionnaires.data, 'id', 'name', 'parentQuestionnaireId'),
      ['label'],
      ['asc'],
    )
  }

  /**
   * Get Questionnaire by id
   * @param {string} accessToken
   * @param {IAppContextState} appContext
   * @param {string} id
   * @returns {Promise<IQuestionnaire>}
   */
  public static readonly getQuestionnaireById = async (
    accessToken: string,
    appContext: IAppContextState,
    id: string,
  ): Promise<IQuestionnaire> => {
    const axiosService = new AxiosService(accessToken)
    const questionnaire: AxiosResponse<IQuestionnaire> = await axiosService.get(
      getQuestionnaireByIdAPIPath(id),
      appContext.tenantId,
    )
    return questionnaire.data
  }

  /**
   * Get Selected Questionnaire
   * @param {string} accessToken
   * @param {IAppContextState} appContext
   * @returns {Promise<IOption[]>}
   */
  public static readonly getSelectedQuestionnaire = async (
    accessToken: string,
    appContext: IAppContextState,
  ): Promise<IProjectQuestionnaire> => {
    const { tenantId, itemId, projectId } = appContext
    const axiosService = new AxiosService(accessToken)
    const questionnaire: AxiosResponse<IProjectQuestionnaire> = await axiosService.get(
      getSelectedQuestionnaireAPIPath(projectId, itemId),
      tenantId,
    )
    return questionnaire.data
  }

  /**
   * Update category data by category id
   * @param {ICategory[]} categories
   * @param {ICategory} updatedCategory
   * @param {string} categoryId
   * @returns {ICategory[]}
   */
  public static readonly updateCategoryById = (
    categories: ICategory[],
    updatedCategory: ICategory,
    categoryId: string,
  ): ICategory[] => {
    return categories.map((categoryParam: ICategory) => {
      if (_.isEqual(categoryParam.id, categoryId)) {
        return updatedCategory
      }
      return categoryParam
    })
  }

  /**
   * Get Parent Questionnaire options
   * @param {IProjectApp[]} projectApps
   * @param {IParentQuestionnaire[]} parentQuestionnaires
   * @param {boolean} isProjectMember
   * @returns {IOption[]}
   */
  public static readonly getParentQuestionnaireOptions = (
    projectApps: IProjectApp[],
    parentQuestionnaires: IParentQuestionnaire[],
  ): IOption[] => {
    let options: IOption[] = []

    for (let projectApp of projectApps) {
      for (let parentQuestionnaire of parentQuestionnaires) {
        if (_.isEqual(projectApp.itemId, parentQuestionnaire.itemId)) {
          const approvalData = ReviewHelper.getApprovalData(parentQuestionnaire.approval)
          options.push({
            id: parentQuestionnaire.itemId,
            label: projectApp.location.application.name,
            subLabel: projectApp.location.phase.name,
            tag: {
              label: approvalData ? approvalData.statusStr : QUESTIONNAIRE_STATUS_VALUES.DRAFT,
              variant: approvalData ? approvalData.statusVariant : TAG_VARIANTS.NEUTRAL,
            },
          })
        }
      }
    }
    return options
  }

  /**
   * Get Parent Questionnaires
   * @param {string} accessToken
   * @param {string} tenantId
   * @param {string} questionnaireId
   * @param {string} projectId
   * @returns {Promise<IOption[]>}
   */
  public static readonly getParentQuestionnaires = async (
    accessToken: string,
    tenantId: string,
    parentQuestionnaireId: string,
    projectId: string,
  ): Promise<IOption[]> => {
    const axiosService = new AxiosService(accessToken)
    const parentQuestionnaires: AxiosResponse<IParentQuestionnaire[]> = await axiosService.get(
      getParentQuestionnairesAPIPath(parentQuestionnaireId, projectId),
      tenantId,
    )

    let options: IOption[] = []
    if (!_.isEmpty(parentQuestionnaires.data)) {
      const projectApps: AxiosResponse<IProjectApp[]> = await axiosService.post(
        getProjectAppsAPIPath(projectId),
        {},
        tenantId,
      )

      options = QuestionnaireHelper.getParentQuestionnaireOptions(projectApps.data, parentQuestionnaires.data)
    }
    return options
  }

  /**
   * Validate app
   * @param {IQuestionnaireState} questionnaire
   * @param {Function} showAlert
   * @param {Function} dispatch
   * @returns {void}
   */
  public static readonly validateApp = (questionnaire: IQuestionnaireState, dispatch: Function): boolean => {
    const { app } = questionnaire

    if (!app) return false

    const formValidation = new FormValidation()
    const updatedApp = formValidation.validateAppByCategoryId(app)

    if (!updatedApp.isValid) {
      dispatch(
        showAlert({
          message: ERR_REQ_FIELD_APP,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      )
      const updatedState: IQuestionnaireState = { ...questionnaire, app: updatedApp }
      dispatch(setState(updatedState))
      return false
    }
    return true
  }

  private static readonly saveStep = async ({
    accessToken,
    appContext,
    questionnaireState,
    app,
    projectQuestionnaireId,
    dispatch,
    showAlert,
    currentCategory,
    nextCategory,
    stepAction,
  }: {
    accessToken: string
    appContext: IAppContextState
    questionnaireState: IQuestionnaireState
    app: IApp
    projectQuestionnaireId: string
    nextCategory: ICategory
    dispatch: Function
    showAlert: Function
    currentCategory: ICategory
    stepAction: STEP_ACTIONS
  }) => {
    let appStatus = questionnaireState.appStatus
    let updatedApp = app
    const formHelper = new FormHelper()
    let prevCategory: ICategory = currentCategory

    if (_.isEqual(stepAction, STEP_ACTIONS.NEXT) || _.isEqual(stepAction, STEP_ACTIONS.STEP)) {
      dispatch(setLoading(true))
      prevCategory = await this.onSaveProgress(
        accessToken,
        appContext,
        questionnaireState,
        projectQuestionnaireId,
        prevCategory,
        dispatch,
      )

      updatedApp = formHelper.updateCategoryStatus(updatedApp, prevCategory.id, false)
      updatedApp = {
        ...updatedApp,
        categories: this.updateCategoryById(updatedApp.categories, prevCategory, prevCategory.id),
      }
      updatedApp = await FieldCommentHelper.updateCommentForCategory(
        accessToken,
        appContext.tenantId,
        projectQuestionnaireId,
        updatedApp,
        nextCategory.id,
        showAlert,
      )
      appStatus = {
        ...questionnaireState.appStatus,
        isAppTouched: false,
      }
    } else {
      updatedApp = formHelper.updateCategoryStatus(updatedApp, prevCategory.id, false)
    }

    dispatch(
      setState({
        ...questionnaireState,
        appStatus,
        loading: false,
        app: {
          ...updatedApp,
          currentCategoryId: nextCategory.id,
        },
        activeStep: nextCategory.categoryIndex,
      }),
    )
  }

  /**
   * Handle Stepper change
   */
  public static readonly handleStep = async ({
    accessToken,
    appContext,
    questionnaireState,
    projectQuestionnaireId,
    stepNumber,
    dispatch,
    stepAction,
    nextCategory,
    t,
  }: IParamHandleStep) => {
    const { app, activeStep } = questionnaireState
    try {
      if (!app) {
        return
      }

      if (_.gt(stepNumber, app.categories.length)) {
        return
      }

      if (_.isEqual(stepNumber, 0) && _.isEqual(stepAction, STEP_ACTIONS.PREVIOUS)) {
        return
      }

      if (_.isEqual(stepAction, STEP_ACTIONS.STEP) && _.isEqual(nextCategory.id, app.currentCategoryId)) {
        return
      }

      const formValidation = new FormValidation()
      let updatedApp = formValidation.validateAppByCategoryId(app, app.currentCategoryId)
      let currentCategory: ICategory = updatedApp.categories[activeStep - 1]

      if (!currentCategory.isValid && app.isAppEditor) {
        dispatch(
          showAlert({
            message: ERR_REQ_FIELD_APP,
            type: TOAST_MESSAGE_TYPES.ERROR,
          }),
        )
        dispatch(
          setState({
            ...questionnaireState,
            app: updatedApp,
          }),
        )
        return
      }

      if (_.isEqual(stepAction, STEP_ACTIONS.STEP) && app.isAppEditor) {
        updatedApp = formValidation.validatePreviousCategories(app, nextCategory)
      }

      await this.saveStep({
        accessToken,
        app: _.cloneDeep(updatedApp),
        dispatch,
        appContext,
        projectQuestionnaireId,
        questionnaireState,
        currentCategory,
        nextCategory,
        showAlert,
        stepAction,
      })
    } catch (error) {
      dispatch(setLoading(false))

      const err = error as AxiosError
      const errorMessage: string = _.isEqual(err.response?.status, HTTP_STATUS_CODES.FORBIDDEN)
        ? t('questionnaire.error.unauthorized_save')
        : t('questionnaire.error.save')
      dispatch(setErrorAlert(errorMessage))
    }
  }

  /**
   * Save form data
   * @param {string} accessToken
   * @param {IAppContextState} appContext
   * @param {IQuestionnaireState} questionnaireState
   * @param {string} projectQuestionnaireId
   * @param {ICategory} category
   * @param {Function} dispatch
   * @return {Promise<ICategory>}
   */
  public static readonly onSaveProgress = async (
    accessToken: string,
    appContext: IAppContextState,
    { app }: IQuestionnaireState,
    projectQuestionnaireId: string,
    category: ICategory,
    dispatch: Function,
  ): Promise<ICategory> => {
    if (!app?.isAppEditor) {
      return category
    }

    const { itemId, projectId, tenantId, homeUrl } = appContext
    if (!app || !category.dirty) {
      return category
    }

    const submitHelper = new SubmitHelper()

    const formHelper = new FormHelper()
    let updatedCategory = await formHelper.preSaveCheck(category, accessToken, tenantId, homeUrl, projectId)
    const FormData = submitHelper.onSaveProgress(updatedCategory)
    await submitHelper.saveToDb(accessToken, itemId, projectId, tenantId, FormData, projectQuestionnaireId)

    updatedCategory = FormHelper.updateCategoryFieldInitialValue(updatedCategory)

    dispatch(
      setApp({
        ...app,
        categories: this.updateCategoryById(app.categories, updatedCategory, updatedCategory.id),
      }),
    )
    return updatedCategory
  }

  /**
   * Load initial data
   */
  public static readonly initLoad = async (
    accessToken: string,
    appContext: IAppContextState,
    questionnaire: IQuestionnaireState,
    projectBriefState: IProjectBriefState,
    projectQuestionnaireId: string,
    dispatch: Function,
  ) => {
    const { projectId, tenantId, userEmail } = appContext
    try {
      if (!projectId) {
        return
      }
      dispatch(
        setState({
          ...questionnaire,
          activeStep: 1,
          app: null,
        }),
      )

      const formHelper = new FormHelper()
      const axiosService = new AxiosService(accessToken)

      const response: AxiosResponse<IResponseApp> = await axiosService.get(
        getQuestionnaireAPIPath(projectQuestionnaireId),
        tenantId,
      )

      const projectInfo: IProject | null = await ProjectHelper.getProjectInfo(
        accessToken,
        projectId,
        tenantId,
        userEmail,
      )

      if (!_.isNull(projectInfo)) {
        dispatch(setReviewers(projectInfo.members))
      }

      let isAppEditable = _.isNull(projectBriefState.questionnaire.approval)
        ? true
        : _.isEqual(projectBriefState.questionnaire.approval?.status, QUESTIONNAIRE_STATUS.FAILED)

      const UpdatedApp: IApp = await formHelper.getApp({
        accessToken,
        isAppEditable,
        isProjectMember: !_.isNull(projectInfo),
        permissions: appContext.permissions,
        projectId: projectId,
        projectQuestionnaireId,
        showAlert,
        tenantId: tenantId,
        app: response.data,
      })

      dispatch(setApp(UpdatedApp))
    } catch {
      dispatch(setError())
    }
  }
}
