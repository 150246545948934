import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import styles from 'app/components/formPermissions/userPermissionStatus/UserPermissionStatus.module.scss'
import { WppInlineMessage, WppTooltip } from 'buildingBlocks'
import { RootState } from 'store'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'

/**
 * User Permission Status component
 * Shown when user does not have permission to edit the form
 */
const UserPermissionStatus: React.FC = (): React.ReactElement => {
  const { questionnaire } = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const { app } = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const { t } = useTranslation()

  const isAppEditor = app!.isAppEditor && app!.isProjectMember

  if (questionnaire.approval || isAppEditor) return <></>

  return (
    <WppTooltip
      text={t('form_permissions.user_cannot_edit_tooltip')}
      component={
        <WppInlineMessage
          size="s"
          message={t('form_permissions.user_cannot_edit_message')}
          className={styles.inlineMessage}
          type="information"
        />
      }
    />
  )
}

export default UserPermissionStatus
