import _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/Form.module.scss'
import { WppTypography } from 'buildingBlocks'
import IField from 'interfaces/field/IField'
import FormFieldHelper from 'utils/formField/FormFieldHelper'
interface IFormFieldReadOnlyProps {
  /** IField interface */
  field: IField
  /** Element field type */
  fieldType: string
}

/**
 * Create empty form field
 * @param {object} props
 * @param {IField} props.field
 * @param {IField} props.id
 */
const FormFieldReadOnly: React.FC<IFormFieldReadOnlyProps> = ({
  field,
  fieldType,
}: IFormFieldReadOnlyProps): React.ReactElement => {
  const { id, value, fieldConfig } = field
  const { options } = fieldConfig
  const formFieldHelper = new FormFieldHelper()

  const fieldValue = formFieldHelper.getReadOnlyFieldValue(field, value, options, fieldType)

  return (
    <WppTypography
      type="s-body"
      aria-disabled="true"
      className={_.isEqual(fieldValue.length, 0) ? styles.formReadOnlyEmpty : styles.formReadOnlyGeneral}
      id={id ?? `readonly-${field.id}`}
    >
      {fieldValue}
    </WppTypography>
  )
}

export default FormFieldReadOnly
