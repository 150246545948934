import React from 'react'
import { createPortal } from 'react-dom'

import styles from 'app/components/help/Help.module.scss'
import { WppSideModal } from 'buildingBlocks'

import 'app/components/help/styles.scss'

interface ISideModalProps {
  /** Modal title */
  title: string
  /** Modal content */
  content: string
  /** If modal is open */
  isModalOpen: boolean
  /** handle modal events */
  handleModalAction: () => void
}

/**
 * Help component
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.content
 * @param {boolean} props.isModalOpen
 * @param {Function} props.handleModalAction
 */
const Help: React.FC<ISideModalProps> = ({
  title,
  content,
  isModalOpen,
  handleModalAction,
}: ISideModalProps): React.ReactElement => (
  <>
    {createPortal(
      <WppSideModal className={styles.modal} onWppSideModalClose={handleModalAction} open={isModalOpen}>
        <h3 slot="header">{title}</h3>
        <div slot="body">
          <div
            className="modalBody"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      </WppSideModal>,
      document.body,
    )}
  </>
)

export default Help
