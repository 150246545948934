// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gswsB{margin-bottom:24px;display:flex;flex-direction:column;justify-content:center;position:sticky;z-index:10;background-color:var(--wpp-grey-color-000);min-height:60px}.gswsB .byZfQ{width:100%;position:absolute;bottom:0px}.m9vhP{display:flex;align-items:center;justify-content:space-between;padding:12px 8px}`, "",{"version":3,"sources":["webpack://./src/app/components/toolbar/Toolbar.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,YAAA,CAEA,qBAAA,CAEA,sBAAA,CAEA,eAAA,CACA,UAAA,CACA,0CAAA,CACA,eAAA,CAEA,cACE,UAAA,CACA,iBAAA,CACA,UAAA,CAIJ,OACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,gBAAA","sourcesContent":[".headerContainer {\n  margin-bottom: 24px;\n  display: flex;\n  -ms-flex-direction: column;\n  flex-direction: column;\n  -ms-flex-pack: justify;\n  justify-content: center;\n  position: -webkit-sticky;\n  position: sticky;\n  z-index: 10;\n  background-color: var(--wpp-grey-color-000);\n  min-height: 60px;\n\n  .divider {\n    width: 100%;\n    position: absolute;\n    bottom: 0px;\n  }\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": `gswsB`,
	"divider": `byZfQ`,
	"header": `m9vhP`
};
export default ___CSS_LOADER_EXPORT___;
