import { WppDatepickerCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/Form.module.scss'
import { WppDatepicker } from 'buildingBlocks'
import { FIELD_TYPES } from 'config/enums'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import { DatePickerEventDetail } from 'utils/types'

interface IFormFieldDatePickerProps {
  /** IField interface */
  field: IField
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create textarea field
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.handleChange
 */
const FormFieldDatePicker: React.FC<IFormFieldDatePickerProps> = ({
  field,
  handleChange,
}: IFormFieldDatePickerProps): React.ReactElement => {
  const { disabled, fieldConfig, value, messageType, errorMessage, isRequired } = field
  const { placeholder } = fieldConfig
  const datePickerReadOnlyClass = disabled ? styles.datePickerReadOnly : ''

  return (
    <WppDatepicker
      onWppChange={(event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
        handleChange({
          field,
          value: !event.detail.formattedDate ? null : event.detail.formattedDate,
        })
      }}
      locale={{
        dateFormat: 'MM/dd/yyyy',
      }}
      onWppDateClear={() => {
        handleChange({
          field,
          value: _.isEqual(field.type, FIELD_TYPES.DATE_RANGE_PICKER) ? [] : null,
        })
      }}
      range={_.isEqual(field.type, FIELD_TYPES.DATE_RANGE_PICKER)}
      required={isRequired}
      placeholder={placeholder}
      id={`datepicker-${field.id}`}
      messageType={messageType}
      message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
      className={
        _.isEqual(messageType, 'error') ? '' : classNames(styles.customStyleDefaultMessage, datePickerReadOnlyClass)
      }
      value={value}
    />
  )
}

export default FormFieldDatePicker
