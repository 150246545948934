import classNames from 'classnames'
import _ from 'lodash'

import commonStyles from 'app/components/form/Form.module.scss'
import styles from 'app/components/form/formField/formFieldAutoComplete/FormFieldAutoComplete.module.scss'
import { WppAvatar, WppPill } from 'buildingBlocks'
import IOption from 'interfaces/common/IOption'
import { AutocompleteDefaultOption } from 'utils/types'

interface SelectedValuesComponent {
  values: AutocompleteDefaultOption[]
  onCloseClick?: (selectedValue: number | string) => void
  disabled: boolean
  showAvatar: boolean
}

const SelectedValues = ({ values, onCloseClick, disabled, showAvatar }: SelectedValuesComponent) => {
  const selectedValuesReadOnlyClass = disabled ? commonStyles.selectedValuesReadOnly : ''
  return (
    <div slot="selected-values" className={classNames(styles.customStyleDefaultMessage, selectedValuesReadOnlyClass)}>
      {values.map((option: IOption) => (
        <WppPill
          label={option.displayLabel}
          removable={!disabled}
          value={option.id}
          onWppClose={() => onCloseClick && onCloseClick(option.id)}
          type="display"
          className={styles.pill}
          key={option.id}
          id={`pile-${option.id}`}
          name={option.displayLabel}
        >
          {showAvatar && <WppAvatar name={option.label} src={option.avatarUrl} slot="icon-start" />}
        </WppPill>
      ))}
      {disabled && _.isEqual(values.length, 0) && <div className={commonStyles.formReadOnlyEmpty} />}
    </div>
  )
}

export default SelectedValues
