import { AxiosResponse } from 'axios'
import { ContentState, convertFromRaw, EditorState } from 'draft-js'
import _ from 'lodash'

import {
  addCommentAPIPath,
  deleteCommentAPIPath,
  editCommentAPIPath,
  getCommentUniqueUsersAPIPath,
  getMoreCommentsByStartDateAPIPath,
} from 'config/apiPaths'
import { COMMENT_ACTION_TYPES, TOAST_MESSAGE_TYPES } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import IAvatar from 'interfaces/common/IAvatar'
import IComment from 'interfaces/field/fieldComment/IComment'
import ICommentUser from 'interfaces/field/fieldComment/ICommentUser'
import IFieldComment from 'interfaces/field/fieldComment/IFieldComment'
import ICommentMetadata from 'interfaces/field/fieldComment/utils/ICommentMetadata'
import IField from 'interfaces/field/IField'
import IForm from 'interfaces/form/IForm'
import IParamHandleComment from 'interfaces/questionnaire/params/IParamHandleComment'
import AxiosService from 'lib/AxiosService'
import { setApp, setState } from 'store/reducers/questionnaireSlice'
import QuestionnaireHelper from 'utils/questionnaire/QuestionnaireHelper'

export default class FieldCommentHelper {
  /**
   * Get comments by category id
   * @param {string} accessToken
   * @param {string} tenantId
   * @param {string} projectQuestionnaireId
   * @param {ICategory} category
   * @returns {Promise<ICategory>}
   */
  public static async getCommentsByCategoryId(
    accessToken: string,
    tenantId: string,
    projectQuestionnaireId: string,
    category: ICategory,
    showAlert: Function,
  ): Promise<ICategory> {
    const axiosService = new AxiosService(accessToken)
    let metadata: ICommentMetadata<ICommentUser> | null = null
    try {
      const response: AxiosResponse<ICommentMetadata<ICommentUser>> = await axiosService.get(
        getCommentUniqueUsersAPIPath(projectQuestionnaireId, category.id),
        tenantId,
      )
      metadata = response.data
    } catch {
      showAlert('Unable to fetch comments due to an error.', TOAST_MESSAGE_TYPES.ERROR)
    }

    const forms: IForm[] = []

    for (let form of category.forms) {
      let fields: IField[] = []
      for (let field of form.fields) {
        let childFields: IField[] = []

        for (let childField of field.children) {
          childFields.push({
            ...childField,
            fieldComment: {
              comments: [],
              startDate: null,
              page: 1,
              uniqueUsers: this.getCommentUsersByFormFieldId(metadata, childField.formFieldId),
              hasMore: true,
            },
          })
        }

        fields.push({
          ...field,
          children: childFields,
          fieldComment: {
            comments: [],
            startDate: null,
            page: 1,
            uniqueUsers: this.getCommentUsersByFormFieldId(metadata, field.formFieldId),
            hasMore: true,
          },
        })
      }

      forms.push({
        ...form,
        fields,
      })
    }

    return {
      ...category,
      forms,
      isCommentLoaded: true,
    }
  }

  /**
   * Get comment
   * @param {IComment} comment
   * @returns {IComment}
   */
  public static getComment(comment: IComment): IComment {
    return {
      ...comment,
      issuerFullname: `${comment.issuerFirstname} ${comment.issuerLastname}`,
    }
  }

  /**
   * Get unique commented users by form field id
   * @param {ICommentMetadata} metadata
   * @param {string} formFieldId
   * @returns {ICommentUser[]}
   */
  public static getCommentUsersByFormFieldId(
    metadata: ICommentMetadata<ICommentUser> | null,
    formFieldId: string,
  ): ICommentUser[] {
    if (_.isNull(metadata)) {
      return []
    }
    for (let partition of metadata.partitions) {
      if (_.isEqual(partition.metadata.formFieldId, formFieldId)) {
        return partition.data
      }
    }
    return []
  }

  /**
   * Get unique users who has commented on the field
   * @param {ICommentUser[]} users
   * @returns {IAvatar[]}
   */
  public static getCommentUsers(users: ICommentUser[]): IAvatar[] {
    return _.uniqBy(
      users.map((user: ICommentUser) => ({
        name: `${user.firstname} ${user.lastname} - ${user.email}`,
        src: user.avatarUrl ?? '',
        email: user.email,
      })),
      'email',
    )
  }

  /**
   * Get editor character count
   * @param {EditorState} editorState
   * @returns {number}
   */
  public static getCharCount(editorState: EditorState): number {
    const charCount = editorState.getCurrentContent().getPlainText().trim().length
    if (_.isEqual(charCount, 0)) {
      return charCount
    }

    return editorState.getCurrentContent().getPlainText().length
  }

  /**
   * Get field comment data
   * @param {IField[]} field
   * @param {string} formFieldId
   * @param {IFieldComment} fieldComment
   * @returns {IField}
   */
  public static getFieldCommentData(field: IField, formFieldId: string, fieldComment: IFieldComment): IField {
    const commentUsers: ICommentUser[] = _.uniqBy(
      fieldComment.comments.map(({ issuerAvatarUrl, issuerEmail, issuerFirstname, issuerLastname }: IComment) => ({
        avatarUrl: issuerAvatarUrl,
        email: issuerEmail,
        firstname: issuerFirstname,
        lastname: issuerLastname,
      })),
      'email',
    )

    const updatedFieldComment: IFieldComment = {
      ...fieldComment,
      uniqueUsers: commentUsers,
    }

    if (_.isEqual(field.formFieldId, formFieldId)) {
      return {
        ...field,
        fieldComment: updatedFieldComment,
      }
    }

    const children: IField[] = field.children.map((childField: IField) => {
      if (_.isEqual(childField.formFieldId, formFieldId)) {
        return this.getFieldCommentData(childField, formFieldId, fieldComment)
      }
      return childField
    })
    return {
      ...field,
      children,
    }
  }

  /**
   * Get form field comment data
   * @param {ICategory} category
   * @param {string} formFieldId
   * @param {IFieldComment} fieldComment
   * @returns {IForm[]}
   */
  public static getFormCommentData(category: ICategory, formFieldId: string, fieldComment: IFieldComment): IForm[] {
    const forms: IForm[] = []
    for (let form of category.forms) {
      const fields: IField[] = []
      for (let field of form.fields) {
        const updatedField: IField = this.getFieldCommentData(field, formFieldId, fieldComment)
        fields.push(updatedField)
      }

      forms.push({
        ...form,
        fields,
      })
    }
    return forms
  }

  /**
   * Update comment data for category
   * @param {string} accessToken
   * @param {string} tenantId
   * @param {string} projectQuestionnaireId
   * @param {IApp} app
   * @param {string} categoryId
   * @param {Function} showAlert
   * @returns {Promise<IApp>}
   */
  public static async updateCommentForCategory(
    accessToken: string,
    tenantId: string,
    projectQuestionnaireId: string,
    app: IApp,
    categoryId: string,
    showAlert: Function,
  ): Promise<IApp> {
    const categories: ICategory[] = []
    for (let category of app.categories) {
      if (_.isEqual(category.id, categoryId) && !category.isCommentLoaded) {
        const updateCategory = await this.getCommentsByCategoryId(
          accessToken,
          tenantId,
          projectQuestionnaireId,
          category,
          showAlert,
        )
        categories.push(updateCategory)
      } else {
        categories.push(category)
      }
    }
    return {
      ...app,
      categories,
    }
  }

  /**
   * Infinite load more comments
   * @param {IField} field
   * @param {number} page
   * @param {string} startDate
   * @returns {Promise<IComment[]>}
   */
  public static async loadMoreComment(field: IField, page: number, startDate: string): Promise<IComment[]> {
    const { accessToken, projectQuestionnaireId, tenantId } = field.fieldConfig
    const axiosService = new AxiosService(accessToken ?? '')

    const metadata: AxiosResponse<ICommentMetadata<IComment>> = await axiosService.get(
      getMoreCommentsByStartDateAPIPath(projectQuestionnaireId, field.formFieldId, startDate, page),
      tenantId,
    )

    for (let partition of metadata.data.partitions) {
      return partition.data.map((comment: IComment) => this.getComment(comment))
    }
    return []
  }
  /**
   * Set comments for form field
   * @param {IApp | null} app
   * @param {IField} field
   * @param {IFieldComment} fieldComment
   * @param {Function} dispatch
   * @returns {void}
   */
  public static setCommentsForFormField(
    app: IApp | null,
    field: IField,
    fieldComment: IFieldComment,
    dispatch: Function,
  ): void {
    if (_.isNull(app)) {
      return
    }
    const { comments } = fieldComment
    if (_.isEmpty(comments)) {
      return
    }
    const categories: ICategory[] = []

    for (let inputCategory of app.categories) {
      if (_.isEqual(inputCategory.id, field.fieldConfig.categoryId)) {
        categories.push({
          ...inputCategory,
          forms: this.getFormCommentData(inputCategory, field.formFieldId, fieldComment),
        })
      } else {
        categories.push(inputCategory)
      }
    }

    dispatch(
      setApp({
        ...app,
        categories,
      }),
    )
  }

  /**
   * Get updated categories after comment change
   */
  public static getUpdatedCategories(app: IApp, category: ICategory, field: IField, fieldComment: IFieldComment) {
    const categories: ICategory[] = []
    for (let inputCategory of app.categories) {
      if (category && _.isEqual(inputCategory.id, category.id)) {
        categories.push({
          ...category,
          forms: this.getFormCommentData(category, field.formFieldId, fieldComment),
        })
      } else {
        categories.push(inputCategory)
      }
    }
    return categories
  }

  /**
   * Handle comment action Edit, add, delete
   */
  public static async handleCommentAction(params: IParamHandleComment): Promise<void> {
    if (_.isEqual(params.type, COMMENT_ACTION_TYPES.EDIT)) {
      await this.editComment(params)
    } else if (_.isEqual(params.type, COMMENT_ACTION_TYPES.DELETE)) {
      await this.deleteComment(params)
    } else if (_.isEqual(params.type, COMMENT_ACTION_TYPES.ADD)) {
      await this.addComment(params)
    }
  }
  /**
   * Delete comment
   */
  public static async deleteComment({
    accessToken,
    projectQuestionnaireId,
    field,
    category,
    updatedComment,
    questionnaireState,
    dispatch,
    appContext,
  }: IParamHandleComment): Promise<void> {
    const { app } = questionnaireState
    if (!app || !updatedComment) return
    dispatch(setState({ ...questionnaireState, loading: true }))

    const axiosService = new AxiosService(accessToken)
    const updatedCategory = await QuestionnaireHelper.onSaveProgress(
      accessToken,
      appContext,
      questionnaireState,
      projectQuestionnaireId,
      category,
      dispatch,
    )

    await axiosService.delete(deleteCommentAPIPath(updatedComment.id), appContext.tenantId)

    const fieldComment: IFieldComment = {
      ...field.fieldComment,
      comments: field.fieldComment.comments.filter((comment: IComment) => !_.isEqual(comment.id, updatedComment.id)),
    }

    dispatch(
      setState({
        ...questionnaireState,
        app: {
          ...app,
          categories: this.getUpdatedCategories(app, updatedCategory, field, fieldComment),
        },
        loading: false,
      }),
    )
  }

  /**
   * Edit comment
   */
  public static async editComment({
    accessToken,
    projectQuestionnaireId,
    field,
    category,
    updatedComment,
    questionnaireState,
    dispatch,
    appContext,
    value,
    mentions,
    config,
  }: IParamHandleComment): Promise<void> {
    const { app } = questionnaireState
    if (!app || !updatedComment) return
    dispatch(setState({ ...questionnaireState, loading: true }))

    const axiosService = new AxiosService(accessToken)
    const updatedCategory = await QuestionnaireHelper.onSaveProgress(
      accessToken,
      appContext,
      questionnaireState,
      projectQuestionnaireId,
      category,
      dispatch,
    )

    const response: AxiosResponse<IComment> = await axiosService.put(
      editCommentAPIPath(updatedComment.id),
      {
        value,
        mentions,
        config,
      },
      appContext.tenantId,
    )
    const comment: IComment = response.data

    const fieldComment: IFieldComment = {
      ...field.fieldComment,
      comments: field.fieldComment.comments.map((iComment: IComment) => {
        if (_.isEqual(iComment.id, comment.id)) {
          return this.getComment(comment)
        }
        return iComment
      }),
    }

    dispatch(
      setState({
        ...questionnaireState,
        app: {
          ...app,
          categories: this.getUpdatedCategories(app, updatedCategory, field, fieldComment),
        },
        loading: false,
      }),
    )
  }

  /**
   * Add comment
   */
  public static async addComment({
    accessToken,
    projectQuestionnaireId,
    field,
    category,
    value,
    questionnaireState,
    dispatch,
    appContext,
    mentions,
    config,
  }: IParamHandleComment): Promise<void> {
    const { app } = questionnaireState
    if (!app) return
    dispatch(setState({ ...questionnaireState, loading: true }))

    let comment: IComment | null = null

    const axiosService = new AxiosService(accessToken)
    const updatedCategory = await QuestionnaireHelper.onSaveProgress(
      accessToken,
      appContext,
      questionnaireState,
      projectQuestionnaireId,
      category,
      dispatch,
    )
    const response: AxiosResponse<IComment> = await axiosService.post(
      addCommentAPIPath(),
      {
        value,
        categoryId: category.id,
        formFieldId: field.formFieldId,
        projectQuestionnaireId,
        mentions,
        config,
      },
      appContext.tenantId,
    )
    comment = response.data

    const categories: ICategory[] = []
    for (let inputCategory of app.categories) {
      if (updatedCategory && _.isEqual(inputCategory.id, updatedCategory.id) && !_.isNull(comment)) {
        categories.push({
          ...updatedCategory,
          forms: this.getFormCommentData(updatedCategory, field.formFieldId, {
            ...field.fieldComment,
            comments: _.concat(field.fieldComment.comments, this.getComment(comment)),
          }),
        })
      } else {
        categories.push(inputCategory)
      }
    }

    dispatch(
      setState({
        ...questionnaireState,
        app: {
          ...app,
          categories,
        },
        loading: false,
      }),
    )
  }

  /**
   * Get user mentions in the comment
   * @param {any} data
   * @return {string[]} user emails
   */
  public static getMentions(data: any): string[] {
    const mentions: string[] = []
    _.forOwn(data?.entityMap, (value: { data: { mention: { id: string } } }) => {
      mentions.push(value.data.mention.id)
    })

    return mentions
  }

  /**
   * If valid JSON
   * @param {string} value
   * @return {boolean}
   */
  public static isValidJSON(value: string): boolean {
    try {
      JSON.parse(value)
      return true
    } catch {
      return false
    }
  }

  /**
   * Get editor state
   * @param value
   * @param config
   * @return {string[]} user emails
   */
  public static getEditorState(value: any, config: any): EditorState {
    if (config) {
      if (_.has(config, 'blocks')) {
        return EditorState.createWithContent(convertFromRaw(config))
      }
      return EditorState.createEmpty()
    } else if (this.isValidJSON(value)) {
      const val = JSON.parse(value)
      if (_.has(val, 'blocks')) {
        return EditorState.createWithContent(convertFromRaw(val))
      } else if (!_.isEqual(typeof val, 'object')) {
        return EditorState.createWithContent(ContentState.createFromText(value))
      }
      return EditorState.createEmpty()
    }
    return EditorState.createWithContent(ContentState.createFromText(value))
  }
}
