import { find, gt, isEqual } from 'lodash'
import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import styles from 'app/components/stepper/Stepper.module.scss'
import { WppButton } from 'buildingBlocks'
import { APP_ACTIONS, STEP_ACTIONS, TOAST_MESSAGE_TYPES } from 'config/enums'
import ICategory from 'interfaces/category/ICategory'
import { RootState } from 'store'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'

interface IStepperProps {
  /** Handle app Submit/Save Progress/Cancel event*/
  handleAppAction: (
    appAction: string,
    callback: (message: TOAST_MESSAGE_TYPES) => void,
    displayMessage?: boolean,
  ) => void
  noOfCategories: number
  /** Handle Next/Previous step event*/
  handleStep: (stepNumber: number, stepAction: STEP_ACTIONS, nextCategory: ICategory) => void
}

/**
 * Stepper
 * @param {object} props
 * @param {Function} props.handleAppAction
 * @param {number} props.noOfCategories
 * @param {Function} props.handleStep
 */
const Stepper: React.FC<IStepperProps> = ({
  handleAppAction,
  handleStep,
  noOfCategories,
}: IStepperProps): React.ReactElement => {
  const { app, activeStep } = useSelector<RootState, IQuestionnaireState>(
    (state: RootState) => state.questionnaireState,
  )

  if (!app) return <></>

  const prevCategory: ICategory = find<ICategory>(app.categories, { categoryIndex: activeStep - 1 }) as ICategory
  const nextCategory: ICategory = find<ICategory>(app.categories, { categoryIndex: activeStep + 1 }) as ICategory

  return (
    <div className={styles.stepContainer}>
      <WppButton
        id="btn-cancel"
        data-testid="btn-cancel"
        onClick={() => handleAppAction(APP_ACTIONS.CANCEL, () => {})}
        variant="secondary"
      >
        {app.isAppEditor && app.isProjectMember ? <Trans>app.button.cancel</Trans> : <Trans>app.button.exit</Trans>}
      </WppButton>
      {gt(noOfCategories, 1) && (
        <>
          <WppButton
            id="btn-back"
            data-testid="btn-back"
            onClick={() => handleStep(activeStep - 1, STEP_ACTIONS.PREVIOUS, prevCategory)}
            disabled={isEqual(activeStep, 1)}
          >
            <Trans>stepper.previous_step</Trans>
          </WppButton>
          <WppButton
            id="btn-next"
            data-testid="btn-next"
            onClick={() => handleStep(activeStep + 1, STEP_ACTIONS.NEXT, nextCategory)}
            className={styles.btnNextSubmit}
            disabled={isEqual(app.categories.length, activeStep)}
          >
            <Trans>stepper.next_step</Trans>
          </WppButton>
        </>
      )}
    </div>
  )
}

export default Stepper
